import React, { Component } from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Page, DashboardFab, ScrollIntoView } from '../../components';
import FaqItem from './FaqItem';
import { Link, ExternalLink } from '../../components/index';

const styles = theme => {
  return {
    gridItem: {
      paddingBottom: theme.spacing.unit * 2
    },
    publicKey: {
      color: theme.palette.primary.main,
      overflowWrap: 'break-word'
    }
  };
};

@withStyles(styles)
class FaqGridItem extends Component {
  render() {
    const { classes, ...rest } = this.props;
    const initialCollapsed =
      window.location.hash && window.location.hash !== `#${this.props.id}`;
    const scrollIntoView = !window.location.hash === `#${this.props.id}`;
    const faqItem = <FaqItem {...rest} initialCollapsed={initialCollapsed} />;
    return (
      <Grid item xs={12} className={classes.gridItem}>
        {scrollIntoView ? <ScrollIntoView>{faqItem}</ScrollIntoView> : faqItem}
      </Grid>
    );
  }
}

@withStyles(styles)
@withRouter
@observer
class FaqPage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Page title="Frequently Asked Questions">
        <Helmet>
          <title>StellarGuard | FAQ</title>
        </Helmet>
        <Grid container spacing={0}>
          <FaqGridItem
            question="How does StellarGuard work?"
            id="how-does-stellarguard-work"
          >
            <Typography paragraph>
              StellarGuard uses the Stellar&apos;s built in multi-signature
              technology to require a transaction to be signed both by you and
              by StellarGuard before it is considered valid.
            </Typography>

            <Typography paragraph>
              To activate StellarGuard, you first need to add your StellarGuard
              signer public key as an additional signer on your account. You
              also have the option to add a backup signer. The application makes
              it easy to build this transaction so you can submit it to Stellar.
              After adding StellarGuard as an additional signer you must click
              activate to finalize the link to your StellarGuard account. After
              this is done your account is protected by StellarGuard.
            </Typography>

            <Typography paragraph>
              After you have a verified your email address, when you submit a
              transaction to StellarGuard an email authorization code will be
              sent to you. This code will be required to authorize the
              transaction and submit it to the Stellar network.
            </Typography>

            <Typography paragraph>
              You may also choose to add enhanced security by enabling
              two-factor authentication via an authenticator application. When
              you add two-factor authentication to your account, a rotating
              passcode that is generated by a mobile app will be required to
              sign in or authorize transactions.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="How much does it cost?"
            id="how-much-does-it-cost"
          >
            <Typography paragraph>
              StellarGuard is currently 100% free.
            </Typography>

            <Typography paragraph>
              If a paid plan is introduced in the future you will ALWAYS have
              the option to remove the StellarGuard signers from your Stellar
              account, even if you have not yet paid.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="If I am using StellarGuard and a hacker steals my private key, do I lose my XLM?"
            id="hacker-lose-my-xlm"
          >
            <Typography paragraph>
              No! StellarGuard was designed with this scenario. Because your
              account is protected with multisig, a hacker will not be able to
              create valid transactions without the other half of the signature,
              which is protected by StellarGuard.
            </Typography>

            <Typography paragraph>
              If StellarGuard was used during{' '}
              <ExternalLink href="https://cointelegraph.com/news/blackwallet-hack-400k-in-stellar-stolen-hosting-provider-possibly-at-fault">
                the BlackWallet hack
              </ExternalLink>
              , your XLM would have been completely safe.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="What would happen if StellarGuard was hacked? Do I lose my XLM?"
            id="stellarguard-hacked"
          >
            <Typography paragraph>
              No! Because StellarGuard does not ask you for your own secret key,
              a hacker who is able to infiltrate StellarGuard still cannot
              create valid transactions.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="What does adding a backup signer do when setting up multisig?"
            id="backup-signer"
          >
            <Typography paragraph>
              If you feel more comfortable not completely relying on
              StellarGuard, you have the option to add a &quot;Backup
              Signer&quot; when enabling multisig. A backup signer is an
              additional public key that you know the secret key to (does not
              have to be a funded account) which can be used instead of your
              primary secret key to sign transactions. StellarGuard does not
              save the backup signer key.
            </Typography>
            <Typography paragraph>
              If you do choose to add a backup signer, you should make sure you
              keep it in a safe place and do not use it for day-to-day
              transactions, and only use it in an emergency. If a hacker steals
              the backup signer, they will be able to steal your XLM. You may
              add a backup signer at any time by submitting it as a normal
              transaction.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="What are the weights and keys used when setting up multisig through StellarGuard?"
            id="multisig-explained"
          >
            <Typography paragraph>
              The transaction XDR created when setting up multisig with
              StellarGuard is composed of the following parts:
            </Typography>
            <Typography paragraph component="div">
              <ol>
                <li>All signing thresholds are set to 20.</li>
                <li>The master signing key weight is set to 10.</li>
                <li>
                  Your StellarGuard signing key is added as a signer with weight
                  set to 10.
                </li>
                <li>
                  A static StellarGuard key (
                  <span className={classes.publicKey}>
                    GCVHEKSRASJBD6O2Z532LWH4N2ZLCBVDLLTLKSYCSMBLOYTNMEEGUARD{' '}
                  </span>
                  ) is added as a signer with weight set to 1 - this key is not
                  able to sign transactions and is instead used as an indicator
                  for third party wallets that transactions for this account
                  should be submitted to StellarGuard.
                </li>
                <li>
                  If a backup signer was set, it is added as a signer with
                  weight set to 20.
                </li>
              </ol>
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="Does StellarGuard work with my existing wallet?"
            id="supported-wallets"
          >
            <Typography paragraph>
              See the{' '}
              <Link to="/supported-wallets" className={classes.link}>
                Supported Wallets page
              </Link>{' '}
              for a full list of supported wallets.
            </Typography>
            <Typography paragraph>
              Even if your wallet is not listed there, you still may be able to
              use it if it lets you copy{' '}
              <ExternalLink href="https://www.stellar.org/developers/horizon/reference/xdr.html">
                signed transaction XDRs
              </ExternalLink>{' '}
              so you can submit them to StellarGuard.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="Does StellarGuard work with the Stellar Decentralized Exchange (SDEX)?"
            id="sdex"
          >
            <Typography paragraph>
              Yes! See the{' '}
              <Link to="/supported-wallets" className={classes.link}>
                Supported Wallets page
              </Link>{' '}
              for a full list of supported exchanges.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="Is there a version that uses the Stellar Testnet so I can try it out first?"
            id="testnet"
          >
            <Typography paragraph>
              Yes.{' '}
              <ExternalLink href="https://test.stellarguard.me">
                https://test.stellarguard.me
              </ExternalLink>{' '}
              uses the Testnet.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="Why do I need to copy and paste transaction XDRs to StellarGuard instead of just submitting to Stellar network?"
            id="copy-xdrs"
          >
            <Typography paragraph>
              Unfortunately Stellar does not have a notion of
              &quot;half-signed&quot; multi-signature transactions that can be
              submitted to the Stellar network -- those are just rejected with
              an authorization error and not stored anywhere. This means that in
              order to use StellarGuard you must submit your half-signed
              transaction to StellarGuard so it can sign it with its key and
              submit the fully-signed, valid transaction to the Stellar network.
            </Typography>
          </FaqGridItem>
          <FaqGridItem
            question="I'm a wallet developer, how do I submit my users' transactions directly to StellarGuard?"
            id="wallet-developers"
          >
            <Typography paragraph>
              Check out the convient{' '}
              <ExternalLink href="https://github.com/stellarguard/stellarguard-js-sdk">
                StellarGuard JavaScript SDK
              </ExternalLink>{' '}
              for implementation details and examples.
            </Typography>
            <Typography paragraph>
              There are two steps to submit transactions directly to
              StellarGuard via the API.
            </Typography>
            <Typography paragraph component="div">
              <ol>
                <li>
                  Check if a Stellar account is protected by StellarGuard. This
                  can be done by looking at the account.signers property of a
                  Stellar account and checking if there is a signer
                  <span className={classes.publicKey}>
                    {' '}
                    GCVHEKSRASJBD6O2Z532LWH4N2ZLCBVDLLTLKSYCSMBLOYTNMEEGUARD{' '}
                  </span>
                  . If there is, this account is protected by StellarGuard and
                  transactions must be submitted directly to it.
                </li>
                <li>
                  Make a POST call to https://stellarguard.me/api/transactions
                  with the a JSON object in the post body of:
                  <pre>
                    {JSON.stringify(
                      { xdr: '<signed transaction xdr>' },
                      null,
                      2
                    )}
                  </pre>
                </li>
              </ol>
            </Typography>
            <Typography paragraph>
              No authentication is required to submit transactions. That&apos;s
              it! You now support StellarGuard.
            </Typography>
          </FaqGridItem>
        </Grid>
        <DashboardFab />
      </Page>
    );
  }
}

export default FaqPage;
